.cate {
  display: flex;
  border-bottom: 1PX solid rgba(0, 0, 0, 0.2);
}

.cate .item {
  font-size: 0.2rem;
  color: #333;
  line-height: 0.6rem;
  margin-right: 0.6rem;
  transition: .3s;
  cursor: pointer;
}

.cate .item:last-of-type {
  margin-right: 0;
}

.cate .item:hover {
  color: #f49133;
}

.cate .item.active {
  color: #f49133;
  font-weight: bold;
}

.subCate {
  padding: 0.2rem 0 0;
}

.subCate .item {
  display: flex;
}

.subCate .label {
  min-width: 1.25rem;
  flex-shrink: 0;
  padding-right: 0.1rem;
  display: flex;
  position: relative;
}

.subCate .label::after {
  content: '';
  width: 0.01rem;
  height: 0.16rem;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.subCate .label span {
  line-height: 1;
  cursor: default;
  min-width: 0.5rem;
  text-align: justify;
  text-align-last: justify;
}

.subCate .label span:hover {
  color: #f49133;
}

.subCate .tag {
  font-size: 0.16rem;
  color: #333;
  font-weight: bold;
  margin: 0.1rem 0;
  transition: .3s;
  line-height: 1;
  cursor: pointer;
}

.subCate .tag:hover,
.subCate .tag.active {
  color: #f49133;
}

.subCate .subCate_inner {
  flex: 1;
  display: flex;
  padding-left: 0.3rem;
}

.subCate .subCate_item {
  display: flex;
  margin-right: 0.35rem;
}

.subCate .subCate_item:last-of-type {
  margin-right: 0;
}

.subCate .subCate_item.active .tag {
  color: #f49133;
}

.item-select {
  margin: 0.1rem 0;
  width: 3rem;
}