.search {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  position: relative;
  margin-bottom: 0.35rem;
}

.search .line {
  width: 1PX;
  background-color: #d8d8d8;
  left: 2.5rem;
  height: 0.3rem;
  flex-shrink: 0;
}

.search :deep(.ant-select) {
  width: 2.5rem;
}

.search :deep(.ant-select-selection-selected-value) {
  font-size: 0.18rem;
  color: #bbbbbb;
}

.search :deep(.ant-input) {
  height: 0.48rem;
  line-height: 0.48rem;
  background-color: #f5f5f5;
}

.search :deep(.ant-btn) {
  min-width: 1.2rem;
  font-size: 0.18rem;
  height: 0.5rem;
  line-height: 0.5rem;
  color: #000;
  background-color: #79f9d9;
  letter-spacing: 0;
  font-weight: bold;
  border-color: #79f9d9;
}

.search :deep(.ant-btn):focus,
.search :deep(.ant-btn):hover {
  background-color: #79f9d9;
  border-color: #79f9d9;
  color: #000;
  box-shadow: 0 0 0.1rem 0 #79f9d9;
}