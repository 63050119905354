.auto-1440 {
  padding-top: 0.4rem;
  padding-bottom: 0.9rem;
}

.tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab .publish {
  display: flex;
  align-content: center;
  font-size: 0.18rem;
  color: #f49133;
  font-weight: bold;
  cursor: pointer;
}

.tab .publish .svg-icon {
  margin-right: 0.1rem;
}

.tab .publish span {
  line-height: 1;
}

/* fade-transform */

.transform-leave-active,
.transform-enter-active {
  transition: all .5s;
}

.transform-enter {
  opacity: 0;
  transform: translateX(-0.3rem);
}

.transform-leave-to {
  opacity: 0;
  transform: translateX(0.3rem);
}