.hot {
  background-color: #f5f5f5;
}

.hot .auto-1440 {
  display: flex;
  align-items: center;
  position: relative;
}

.hot .label {
  display: flex;
  align-items: center;
  font-size: 0.2rem;
  color: #ff0000;
  font-weight: bold;
  margin-right: 0.4rem;
}

.hot .label .svg-icon {
  margin-right: 0.1rem;
}

.hot .swiper {
  flex: 1;
  height: 0.8rem !important;
}

.hot .swiper .swiper-slide {
  height: 0.8rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.7rem;
  position: relative;
}

.hot .swiper .dot {
  font-size: 0.18rem;
  color: #555555;
}

.hot .swiper .dot:hover {
  color: #00ffc0;
}

.hot .swiper .time {
  font-size: 0.16rem;
  color: #888888;
}

.hot .close {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #bbbbbb;
  cursor: pointer;
  z-index: 2;
}

.hot .close .svg-icon {
  line-height: 1;
  display: block;
  transition: .3s;
}

.hot .close .svg-icon:hover {
  color: #666;
}