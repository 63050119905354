.tab_list {
  display: flex;
}

.tab_list span {
  padding: 0 0.24rem;
  line-height: 0.5rem;
  border-radius: 0.08rem 0.08rem 0 0;
  background-color: #f5f5f5;
  margin-right: 0.05rem;
  font-size: 0.18rem;
  color: #888888;
  transition: background-color 0.3s;
  cursor: pointer;
}

.tab_list span:hover,
.tab_list span.active {
  color: #333333;
  background-color: #e5e5e5;
}